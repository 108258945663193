.drdetails_section {
    background: transparent linear-gradient(180deg, #ebd574 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    padding: 80px 0px;
}

.drdetails_section .drimagediv{
    text-align: center;
    margin-bottom: 40px;
}

.drdetails_section .drimage{
    width: 122px;
height: 103px;
}
.drdetails_section .slider_bt{
  display: flex;
  justify-content: center; 
  padding-top: 40px;
}
.drdetails_section .iconmain{
    font-size: 22px;
}

.drdetails_section .bordermain {
    width: 138px;
    border-bottom: 1px solid black;
}
.drdetails_section .testtext{
    font-family: 'CalvinoGrandeVarTrial', sans-serif !important;
}
@media (min-width: 0px) and (max-width: 485px) {
    .drdetails_section .drimage{
        width: 80px;
    height: 70px;
    }
    .drdetails_section .bordermain {
        width: 90px;
    }
    .drdetails_section {
      
        padding: 40px 0px;
    }
    .drdetails_section .iconmain{
        font-size: 17px;
    }
    
}
@media (min-width: 486px) and (max-width: 767px) {
    .drdetails_section .drimage{
        width: 90px;
    height: 78px;
    }
    .drdetails_section .bordermain {
        width: 100px;
    }
    .drdetails_section {
      
        padding: 50px 0px;
    }
    .drdetails_section .iconmain{
        font-size: 18px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .drdetails_section .drimage{
        width: 95px;
    height: 81px;
    }
    .drdetails_section .bordermain {
        width: 110px;
    }
    .drdetails_section {
      
        padding: 60px 0px;
    }
    .drdetails_section .iconmain{
        font-size: 19px;
    }
}