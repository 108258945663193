
.recentblog_div{
    display: flex;
    background: #F6F6F6 0% 0% no-repeat padding-box;
}

.recentblog_div .recentimgdiv{
    width: 80px;
height: 80px;
}

.recentblog_div .recentimgdiv .recentimage{
    width: 100%;
    height: 100%;
}

.recentblog_div .recenttext {
    padding: 10px 20px;
    display: inline-grid;
    align-content: space-between;
}

.recentblog_div .readd{
    font-size: 10px;
    font-weight: 500;
color: #202020;
font-family: "Roboto", sans-serif;
}