/* .ButtonMain .breadcrumb-item{
font-size: 12px;
} */
.ButtonMain li.breadcrumb-item {
    font-size: 12px !important; 
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
}
 .boldtext{
    font-weight: bold !important;
}
.ButtonMain li.breadcrumb-item:last-child {
    font-weight: bold !important;
}

@media (min-width: 0px) and (max-width: 767px) {
    .ButtonMain li.breadcrumb-item {
        font-size: 10px !important;   
    }
}