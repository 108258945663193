.makemain .makeapoint_section{
    padding: 100px 0px;
    /* position: relative; */
}

.makemain .makeimg_div{
    width: 122px;
height: 103px;
}
.makemain .makeimg_div .makeimg{
    width: 100%;
    height: 100%;
}

.makemain .nametext{
    font-size: 25px;
    font-weight: 600;
    font-family: 'Montserrat';
color: #202020;
}
.makemain .brdrr{
    width: 136px;
height: 1px;
background: #A6A6A6 0% 0% no-repeat padding-box;
margin:  30px 0px;
}
.makemain .workhrtext{
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 600;
color: #202020;
}


.makemain iframe.gmap_iframe {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 6/2; */
}

/* .gmap_canvas{
    position: relative;
} */


.makemain .angleeicn{
position: absolute;
width: 100%;
background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
height: 129px;
bottom: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;

}

.makemain .angicn{
    font-size: 30px;
}

.makemain .mapdiv {
    position: absolute;
    bottom: 0;
left: 0;
width: 100%;
    overflow: hidden;
    height: 0;
    /* transition: max-height 2s ease-in-out; */
    transition: height 0.4s ease-in-out;
    /* transition: max-height 2s ease-in-out, transform 2s ease-in-out; */
}

.makemain .mapdiv.show {
    height: 100%; 
    /* transition: max-height 2s ease-in-out; */
    /* transition: max-height 2s ease-in-out, transform 2s ease-in-out; */
}
.makemain{
    position: relative;
}
@media (min-width:0px) and (max-width:485px) {
    .makemain  .makeimg_div {
        width: 95px;
        height: 76px;
    }
    .makemain .makeapoint_section {
        padding: 40px 0px;
    }
    .makemain .nametext{
        font-size: 18px;
    }
    .makemain  .brdrr {
        margin: 20px 0px;
    }
    .makemain .workhrtext {
        font-size: 15px;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .makemain  .makeimg_div {
        width: 95px;
        height: 76px;
    }
    .makemain  .makeapoint_section {
        padding: 50px 0px;
    }
    .makemain  .nametext{
        font-size: 20px;
    }
    .makemain .brdrr {
        margin: 22px 0px;
    }
    .makemain .workhrtext {
        font-size: 16px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .makemain  .makeimg_div {
        width: 100px;
        height: 80px;
    }
    .makemain .makeapoint_section {
        padding: 60px 0px;
    }
    .makemain .nametext{
        font-size: 20px;
    }
    .makemain  .brdrr {
        margin: 24px 0px;
    }
    .makemain .workhrtext {
        font-size: 17px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .makemain  .makeimg_div {
        width: 105px;
        height: 85px;
    }
    .makemain  .makeapoint_section {
        padding: 70px 0px;
    }
    .makemain .nametext{
        font-size: 22px;
    }
    .makemain  .brdrr {
        margin: 26px 0px;
    }
    .makemain .workhrtext {
        font-size: 18px;
    }
}


/* .gmap_canvas {
    position: relative;
} */

/* .angicn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.angleeicn {
    position: relative;
} */