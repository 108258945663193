

.treatbannersss .Banner_holder{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.treatbannersss .Banner_holder .img-holder{
  aspect-ratio: 1/0.3;
}

.treatbannersss .Banner_holder .img-holder .treatbanner-img {
  aspect-ratio: 1/0.3;
  width: 100%;
  height: 100%;
}

.banner-heading-text-holder {
  position: absolute;
  top: 50%;
  bottom: 50%;
  width: 100%;
  transform: translateY(-40px);
}

.treatbanner .Banner_holder .text-holder h1 {
  text-align: left;
  font-size: 70px;
  font-weight: 600;
  color: #222222;
  text-transform: capitalize;
  font-family: CalvinoGrandeVarTrial;
}

.treatbanner  .textrestore{
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
color: #202020;
}

@media (min-width: 0px) and (max-width: 320px) {
    .treatbanner .Banner_holder .text-holder h1 {
    font-size: 20px;
  }
  .treatbanner  .textrestore {
    font-size: 15px;
}


}

@media (min-width: 321px) and (max-width: 485px) {
    .treatbanner  .Banner_holder .text-holder h1 {
    font-size: 22px;
  }
  .treatbanner  .textrestore {
    font-size: 16px;
}
}

@media (min-width: 486px) and (max-width: 575px) {
    .treatbanner .Banner_holder .text-holder h1 {
    font-size: 25px;
  }
  .treatbanner  .textrestore {
    font-size: 16px;
}
}

@media (min-width: 576px) and (max-width: 765px) {
    .treatbannersss .Banner_holder .text-holder h1 {
        font-size: 30px;
    }
    .treatbanner  .textrestore {
      font-size: 17px;
  }
}

@media (min-width: 766px) and (max-width: 991px) {
    .treatbanner .Banner_holder .text-holder h1 {
    font-size: 35px;
  }
  .treatbanner  .textrestore {
    font-size: 17px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
    .treatbanner .Banner_holder .text-holder h1 {
    font-size: 40px;
  }
  .treatbanner  .textrestore {
    font-size: 18px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .treatbanner .Banner_holder .text-holder h1 {
    font-size: 50px;
  }
  .treatbanner  .textrestore {
    font-size: 19px;
}
}
