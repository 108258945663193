.faqtabs_section{
    margin-bottom: 90px;
}

.faqtabs_section .faq_main_div{
    position: relative;
    padding-top: 15px;
}

.faqtabs_section .tab_section{
    position: absolute;
    width: 100%;
    left: 0;
    top:0

}
.faqtabs_section .faqconts{
   
box-shadow: 0px 3px 6px #00000029;
border-radius: 20px;
padding-top: 45px;

}

.faqtabs_section .tabitemss {
    border: 1px solid #A6A6A6;
    border-radius: 7px;
}
.faqtabs_section .tab_section{
    padding-left: 40px;
}

.faqtabs_section .accordion-button:not(.collapsed) {
    color: #000;
    background-color:#fff;
    box-shadow: none;
}

.faqtabs_section  .accordion-item {
    /* color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg); */
    border: none;
}

.faqtabs_section  .accordheader{
   
    font-size: 18px;
    font-weight: 400;
letter-spacing: 0px;
color: #000000;
font-family: "Roboto", sans-serif;
text-transform: capitalize;
}
.faqtabs_section   .accordion-button {
    font-size: 18px;
    font-weight: 400;
letter-spacing: 0px;
color: #000000;
text-transform: capitalize;
}

.faqtabs_section  .accordion-button:focus {
    /* z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0; */
    box-shadow: none;
}
.faqtabs_section .accordion-button:not(.collapsed)::after {
    color: black;
    filter: brightness(0);
  }
.faqtabs_section .nav-pills .nav-link{
    font-size: 15px !important;
    font-family: "Montserrat", sans-serif;
}
.faqtabs_section .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background: #A6A6A6 0% 0% no-repeat padding-box;
border-radius: 5px;

/* font-size: 15px !important; */
}


@media (min-width: 0px) and (max-width: 485px) {
    .faqtabs_section{
        margin-bottom: 50px;
    }
    .faqtabs_section .faqconts {
        padding-top: 150px;
    }
    .faqtabs_section  .accordion-button {
        font-size: 15px;
    }
    .faqtabs_section .nav-pills .nav-link{
        font-size: 13px !important;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .faqtabs_section{
        margin-bottom: 65px;
    }
    .faqtabs_section .faqconts {
        padding-top: 110px;
    }
    .faqtabs_section  .accordion-button {
        font-size: 16px;
    }
    .faqtabs_section .nav-pills .nav-link{
        font-size: 14px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .faqtabs_section{
        margin-bottom: 80px;
    }
    .faqtabs_section .faqconts {
        padding-top: 75px;
    }
    .faqtabs_section  .accordion-button {
        font-size: 17px;
    }
}