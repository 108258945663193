.universe_section{
    background-image: url('../../../../public/assets/images/homepage/universebanner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
}

.universe_section .universeimage_div{
    height: 478px;
    width: 100%;
}

.universe_section .universeimage{
height: 100%;
max-width: 100%;
width: auto;
object-fit: contain;
}

.universe_section .universetext{
    padding: 25px 0px;
}
.universe_section  .universec_div .text{
    
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
}

@media (min-width: 0px) and (max-width: 485px){
    .universe_section .universeimage_div {
        height: 275px;
    }
    .universe_section  .universec_div .text {
      font-size: 13px;
    }
  }
  @media (min-width: 486px) and (max-width: 767px){
    .universe_section .universeimage_div {
        height: 310px;
    }
    .universe_section  .universec_div .text {
        font-size: 14px;
      }
  }
  
  @media (min-width: 768px) and (max-width: 991px){
  
    .universe_section .universeimage_div {
        height: 330px;
    }
    .universe_section  .universec_div .text {
        font-size: 15px;
      }
  }
  @media (min-width: 992px) and (max-width: 1199px){
  
    .universe_section .universeimage_div {
        height: 370px;
    }
  }