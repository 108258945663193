.service_section{
    margin-top: 6%;
    margin-bottom: 5%;
}

.service_section
  .service_main_div
  .span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  /* background: a6a6a6;
    color: a6a6a6; */
}

.service_section .service_main_div .swiper-pagination-bullet-active {
  width: 44px !important;
  height: 5px;
  border-radius: 5px;
  /* opacity: var(--swiper-pagination-bullet-opacity, 1); */
  background: #a5a5a5;
}

.service_section .service_main_div .swiper-pagination-bullet {
  background: #a5a5a5;
  width: 22px;
  border-radius: 5px;
  height: 5px;
}
.service_section .service_main_div .swiper {
    padding-bottom: 50px;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  
@media (min-width: 0px) and (max-width: 485px) {
   .service_section .service_main_div  .swiper-pagination-bullet-active {
      width: 28px !important;
    }
  
   .service_section .service_main_div  .swiper-pagination-bullet {
      width: 14px;
    }
    
   .service_section .service_main_div  .swiper {
      padding-bottom: 40px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
  @media (min-width: 486px) and (max-width: 767px) {
   .service_section .service_main_div  .swiper-pagination-bullet-active {
      width: 32px !important;
    }
  
   .service_section .service_main_div  .swiper-pagination-bullet {
      width: 16px;
    }
    
    .service_section .service_main_div  .swiper {
        padding-bottom: 40px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
   .service_section .service_main_div  .swiper-pagination-bullet-active {
      width: 36px !important;
    }
  
   .service_section .service_main_div  .swiper-pagination-bullet {
      width: 18px;
    }
    .service_section .service_main_div  .swiper {
        padding-bottom: 40px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
     
  }
  