.footer_div {
    background: #f3f3f3 0% 0% no-repeat padding-box;
}

.footer_div .footer_top {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 20px 20px;
    padding: 30px 20px 20px 20px;
}
.footer_div .top_text {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    margin-bottom: 10px;
    color: #202020;
    font-family: "Montserrat", sans-serif;
}

.footer_div .contact_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer_div .headericn_div {
    height: 27px;
    width: 27px;
    margin-left: 10px;
    background: #a6a6a6 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
}
.footer_div .cart_logo {
    color: #fff;
    font-size: 13px;
}
.footer_div .all_icon {
    display: flex;
    align-items: center;
    padding-top: 10px;
    justify-content: center;
}
.footer_div .footer_miidle {
    padding-top: 20px;
}

.footer_div .bottom_footer_text {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12px;
    color: #202020;
    margin-bottom: 0px;
    padding-bottom: 15px;
    font-family: "Montserrat", sans-serif;
}

.footer_div .padding_div {
    padding-bottom: 40px;
}

.footer_div .footericn {
    color: #a6a6a6;
    padding-right: 10px;
}
.footer_div .footer-main {
    justify-content: space-between;
}
@media (min-width: 0px) and (max-width: 485px) {
    .footer_div .contact_div {
        flex-direction: column;
    }
    .footer_div .padding_div {
        padding-bottom: 15px;
    }
    .footer_div .footer_top {
        padding: 15px 5px 26px 5px;
    }
    .footer_div .top_text {
        font-size: 11px;
    }
    .footer_div .bottom_footer_text {
        font-size: 11px;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .footer_div .padding_div {
        padding-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer_div .padding_div {
        padding-bottom: 25px;
    }
}


