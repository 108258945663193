.blogbanner_section {
    /* background: transparent linear-gradient(359deg, #e7d171 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(359deg, #e4d284 0%, #FFFFFF 75%) 0% 0% no-repeat padding-box;
    padding: 50px 0px;
}



.blogbanner_section .blogmain .blogtitle{
    font-size: 70px;
    font-weight: bold;
color: #202020;


}

.blogbanner_section .blogmain .blogtext{
    font-size: 20px;
    font-weight: 400;
color: #202020;
font-family: 'CalvinoGrandeVarTrial', sans-serif;
}


@media (min-width: 0px) and (max-width: 485px){
    .blogbanner_section {
        padding: 30px 0px;
    }
    
    .blogbanner_section .blogmain .blogtitle{
        font-size: 40px;
    }
    
    .blogbanner_section .blogmain .blogtext{
        font-size: 15px;
    }
   
  }
  @media (min-width: 486px) and (max-width: 767px){
    .blogbanner_section {
        padding: 35px 0px;
    }
    
    .blogbanner_section .blogmain .blogtitle{
        font-size: 50px;
    }
    
    .blogbanner_section .blogmain .blogtext{
        font-size: 16px;
    }
   
  }
  
  @media (min-width: 768px) and (max-width: 991px){
    .blogbanner_section {
        padding: 40px 0px;
    }
    
    .blogbanner_section .blogmain .blogtitle{
        font-size: 55px;
    }
    
    .blogbanner_section .blogmain .blogtext{
        font-size: 17px;
    }
   
  }
  @media (min-width: 992px) and (max-width: 1199px){
    .blogbanner_section {
        padding: 45px 0px;
    }
    
    .blogbanner_section .blogmain .blogtitle{
        font-size: 60px;
    }
    
    .blogbanner_section .blogmain .blogtext{
        font-size: 18px;
    }
   
   
  }