.Terms_And_Condition_Banner .Banner_holder {
  background-image: url(../../../../public/assets/images/Privacy_Policy/Terms_And_Condition_Banner.png);
  aspect-ratio: 1/0.2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: left;
  align-items: center;
}