.AllDetailModal_modal .modal-body .imggdvivv{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 1/0.3;
}



.AllDetailModal_modal .modal-body .imggdvivv .imfluid {
    width: 100%;
    height: 100%;
}

.AllDetailModal_modal .modal-header {
    background: transparent linear-gradient(359deg, #e4d284 0%, #FFFFFF 75%) 0% 0% no-repeat padding-box;
}

.AllDetailModal_modal .modal-header  .modal-title {
    font-size: 20px;
    font-weight: 600;
    margin: 0px auto;
    font-family: 'CalvinoGrandeVarTrial', sans-serif;
}

.AllDetailModal_modal .modal-body {
    height: 410px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.AllDetailModal_modal .modal-footer {
    border: none;
}
.AllDetailModal_modal .close_btn {
    background: #010035 0% 0% no-repeat padding-box;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}
.AllDetailModal_modal .close_btn:hover{
    background: #2421b3 0% 0% no-repeat padding-box;
}

.AllDetailModal_modal .modal-header .btn-close {
    margin: 0px;
}
.AllDetailModal_modal .modal-header  .btn-close:focus {
   
    box-shadow: none;
   
}
@media (min-width: 0px) and (max-width: 320px) {
    .AllDetailModal_modal .modal-body {
        height: 270px;
      
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .AllDetailModal_modal .modal-body {
        height: 300px;
       
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .AllDetailModal_modal .modal-body {
        height: 340px;
       
    }
}




