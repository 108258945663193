@import url("https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* styles.css or index.css */
/* @import '~datatables.net-dt/css/jquery.dataTables.css';
@import '~datatables.net-buttons-dt/css/buttons.dataTables.css'; */
/* @import url("https://fonts.googleapis.com/css2?family=CalvinoGrandeVarTrial&display=swap"); */
/* <link href="https://fonts.googleapis.com/css2?family=CalvinoGrandeVarTrial&display=swap" rel="stylesheet"> */

@import url("https://fonts.googleapis.com/css2?family=CalvinoGrandeVarTrial&display=swap");

body {
    margin: 0px;
    padding: 0px;
    font-family: "Nunito Sans" !important;
}


@font-face {
    font-family: myFont;
    src: url(../public/assets/fonts/NunitoSans-Italic-VariableFont_YTLC\,opsz\,wdth\,wght.ttf);
}

@font-face {
    font-family: CalvinoGrandeVarTrial;
    src: url('../public/assets/images/Calvino-Grande-Bold-trial.ttf')
}

@font-face {
    font-family: ChopinScript;
    src: url('../public/assets/images/ChopinScript.otf')
}
.form-control:focus {
    box-shadow: none !important;
}

.heading{
    /* font-family: CalvinoGrandeVarTrial; */
    /* font-family: 'CalvinoGrandeVarTrial', sans-serif !important; */
    /* font-family: 'CalvinoGrandeVarTrial' !important; */
    font-family: 'CalvinoGrandeVarTrial', sans-serif;
}

.title {
    font-size: 35px;
    letter-spacing: 0.7px;
    color: #202020;
}
a {
    color: #202020 !important;
}

.small_title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 7.68px;
    color: #202020;
}
.text {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}

.small_text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #202020;
    font-family: "Roboto", sans-serif;
}
.text-sub {
    font-family: 'Montserrat';
    font-size: 18px;
    letter-spacing: 8.64px;
    color: #202020;
    font-weight: 600;
}
.text-mini {
    font-family: "Montserrat";
    letter-spacing: 7.5px;
    color: #202020;
    font-size: 15px;
}
.title-main {
    letter-spacing: 0.7px;
    color: #202020;
    font-size: 35px;
    /* font-family: myFont; */
    line-height: 50px;
    font-family: 'CalvinoGrandeVarTrial', sans-serif;
}
.card-title {
    color: #202020;
    font-size: 25px;
    /* font-family: myFont; */
}
.p-text {
    color: #202020;
    font-size: 15px;
    font-family: "Roboto";
}
.testimonial-text {
    font-family: "Montserrat";
    color: #202020;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0px;
}

.text-testimonial {
    font-weight: 500;
    font-size: 15px;
    color: #202020;
    /* margin-bottom: 32px; */
    font-family: Montserrat;
}
span.highlight {
    padding: 7px 5px 5px 5px;
    background-color: #f9da55;
}
/* .main .modal{
  backdrop-filter: brightness(2);
} */

@media (min-width: 0px) and (max-width: 485px) {
    .title {
        font-size: 20px;
    }
    .text-sub {
        font-size: 12px;
    }
    .text-mini {
        font-size: 13px;
    }

    .small_title {
        font-size: 11px;
    }
    .text {
        font-size: 12px;
    }
    .title-main {
        font-size: 20px;
        line-height: 37px;
    }
    .card-title {
        font-size: 20px;
       }
       .p-text {
        font-size: 12px;
    }
    .testimonial-text{
        font-size: 18px;
    }
    .small_text {
        font-size: 12px;
       
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .title {
        font-size: 23px;
    }

    .small_title {
        font-size: 12px;
    }
    .text {
        font-size: 12px;
    }
    .text-sub {
        font-size: 14px;
    }
    .text-mini {
        font-size: 14px;
    }
    .title-main {
        font-size: 24px;
        line-height: 37px;
    }
    .card-title {
        font-size: 22px;
    }
    .p-text {
        font-size: 14px;
    }
    .testimonial-text
    {
        font-size: 20px;
    }
    .card-title{
        font-size: 20px;
       }
       .p-text {
        font-size: 13px;
    }
    .testimonial-text{
        font-size: 20px;
    }
    .small_text {
        font-size: 13px;
       
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .title {
        font-size: 36px;
    }

    .small_title {
        font-size: 13px;
    }
    .text {
        font-size: 13px;
    }
    .text-sub {
        font-size: 14px;
    }
    .text-mini {
        font-size: 14px;
    }
    .title-main {
        font-size: 24px;
        line-height: 44px;
    }
    .card-title {
        font-size: 24px;
    }
    .p-text {
        font-size: 16px;
    }
    .testimonial-text
    {
        font-size: 22px;
    }
    .card-title{
        font-size: 22px;
       }
       .p-text {
        font-size: 14px;
    }
    .testimonial-text{
        font-size: 22px;
    }
    .small_text {
        font-size: 13px;
       
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .title {
        font-size: 30px;
    }

    .small_title {
        font-size: 14px;
    }
    .text {
        font-size: 14px;
    }
    .p-text {
        font-size: 15px;
    }
    .testimonial-text{
        font-size: 24px;
    }
}
