.teethswiper_section .teeth_swiper_main .treatcardimage_div{
    width: 100%;

}
.teethswiper_section .teeth_swiper_main .treatcardimage{
    width: 100%;
    height: 100%;
}


.teethswiper_section .teeth_swiper_main .carrrd_data {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    padding: 20px 10px;
    /* height: 280px; */
}
.teethswiper_section .teeth_swiper_main .servctext {
    height: 200px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.teethswiper_section .teeth_swiper_main .carrrd_data .card-title{
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.teethswiper_section .teeth_swiper_main .servctext .small_text{
    /* display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4; */
}


.teethswiper_section .teeth_swiper_main .imagebig_div{
    height: 385px;
    width: 100%;
    margin-bottom: 17px;
}
.teethswiper_section .teeth_swiper_main  .imgbig{
    height: 100%;
    width: 100%;
}
.teethswiper_section .teeth_swiper_main .imgsmall{
    width: 100%;
    height: 100%;
}
.teethswiper_section .teeth_swiper_main .imagesmall_div{
    height: 184px;
    width: 100%;
    margin-bottom: 17px;
}

.teethswiper_section .teeth_swiper_main  .span.swiper-pagination-bullet.swiper-pagination-bullet-active {
   
    /* background: a6a6a6;
    color: a6a6a6; */
}

.teethswiper_section .teeth_swiper_main .swiper-pagination-bullet-active {
    width: 44px !important;
    height: 5px;
    border-radius: 5px;
    /* opacity: var(--swiper-pagination-bullet-opacity, 1); */
    background: #a5a5a5;
}

.teethswiper_section .teeth_swiper_main .swiper-pagination-bullet {
    background: #a5a5a5 ;
    width: 22px;
    border-radius: 5px;
    height: 5px;
}

.teethswiper_section .teeth_swiper_main  .swiper {
    padding-bottom: 25px;
    margin-bottom: 50px;
    margin-top: 50px;
}

@media (min-width: 0px) and (max-width: 485px) {
    .teethswiper_section .teeth_swiper_main .carrrd_data {
      
        /* height: 480px; */
    }
    .teethswiper_section .teeth_swiper_main .swiper-pagination-bullet-active {
        width: 28px !important;
      
    }
    
    .teethswiper_section .teeth_swiper_main .swiper-pagination-bullet {
       
        width: 14px;
       
    }
    .teethswiper_section .teeth_swiper_main  .swiper {
        margin-top: 30px;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .teethswiper_section .teeth_swiper_main .swiper-pagination-bullet-active {
        width: 32px !important;
       
    }
    
    .teethswiper_section .teeth_swiper_main .swiper-pagination-bullet {
        
        width: 16px;
       
    }
    .teethswiper_section .teeth_swiper_main .carrrd_data {
      
        /* height: auto; */
    }
    .teethswiper_section .teeth_swiper_main  .swiper {
        margin-top: 35px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .teethswiper_section .teeth_swiper_main .swiper-pagination-bullet-active {
        width: 36px !important;      
    }
    
    .teethswiper_section .teeth_swiper_main .swiper-pagination-bullet {  
        width: 18px;   
    }
    .teethswiper_section .teeth_swiper_main  .swiper {
        margin-top: 40px;
    }
}