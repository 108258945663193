.second-header-sec {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1024;
}


.second-header-sec .navbar-expand-lg .navbar-collapse {
  justify-content: space-between;
  align-items: flex-end;
}
.second-header-sec .headericn_div {
  height: 27px;
  width: 27px;
  margin-left: 10px;
  background: #a6a6a6 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}
.second-header-sec .headlogo {
  width: 120px;
  height: 81px;
}

.second-header-sec .navbar-brand {
  padding-bottom: 0px;
  padding-right: 25px;
}

.second-header-sec .dropdown-toggle::after {
  display: none;
}

.second-header-sec {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.second-header-sec .navbar-nav .nav-link {
  color: #202020;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 0px;
  margin: 0px 11px 0px 11px;
  font-family: "Montserrat";
}

.second-header-sec button.accordion-button {
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat";
  color: #202020;
}

.second-header-sec .accordion-button:focus {
  box-shadow: none;
}
.second-header-sec .accordion-button:not(.collapsed)::after {
  color: black;
  filter: brightness(0);
}

/* .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 15px;
    font-weight: 500;
  
   
  } */

.second-header-sec .navbar-nav .nav-link.active {
  color: #000;
  font-weight: 700;
  font-family: "Montserrat";
}

.second-header-sec .navbar-nav .nav-link.show {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

/* .second-header-sec .navbar-nav .dropdown {
    background: #df4223 0% 0% no-repeat padding-box;
    width: 352px;
  } */

.second-header-sec .navbar {
  padding-top: 0px;
  padding-bottom: 10px;
  align-items: end;
  position: relative;
}

/* .second-header-sec .dropdown-toggle.show::after {
    font-size: 20px;
    vertical-align: 0;
    transform: rotate(180deg);
  } */

/* .second-header-sec .dropdown-toggle::after {
    font-size: 20px;
    vertical-align: 0;
  } */

/* .second-header-sec .dropdown-toggle::after {
    position: absolute;
    right: -7px;
    margin-top: 6px;
  } */
.second-header-sec .dropdown {
  position: unset;
}

.second-header-sec .cart_logo {
  color: #fff;
  font-size: 13px;
}

.second-header-sec .navbar-toggler:focus {
  box-shadow: none;
}
.second-header-sec .droprows1 {
  padding: 50px 0px;
  height: 100%;
  background-color: #a6a6a6;
}
.second-header-sec .droprows2 {
  padding: 50px 20px;
}
.second-header-sec .dropdown-menu {
  /* background-color: #0f3768f2; */
  border-radius: 0%;
  /* width: 352px; */
  padding-top: 0%;
  padding-bottom: 0%;
  z-index: 1;
}
.second-header-sec .treat_text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.second-header-sec .treatname_text {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  text-wrap: wrap;
  margin-bottom: 7px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #202020;
  text-transform: capitalize;
  opacity: 1;
  padding-top: 3px;
  transition: padding 0.3s ease;
}

.second-header-sec .treatname_text:hover {
  padding-top: 0px;
  padding-bottom: 3px;
  /* transition: transform .5s ease-in-out; */
}
.second-header-sec .dropdown-item:hover {
  background: #fff 0% 0% no-repeat padding-box;
  padding: 0px;
}

.second-header-sec .dropdown-item:active {
  /* background: #df4223 0% 0% no-repeat padding-box; */

  padding: 0px;
}

.second-header-sec .dropdown-item {
  background-color: #fff;

  padding: 0px;
}

.second-header-sec .tabbmains .treattabitem {
}

.second-header-sec .tabbmains .treattabitem .treattablink {
  padding: 8px 20px !important;
  margin: 0px !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif;
}
.second-header-sec .tabbmains .treattabitem .treattablink:hover {
  background-color: #fff;
  color: #000 !important;
  border-right: 7px solid #f9da55;
}
.second-header-sec .tabbmains .treattabitem .treattablink.active {
  background-color: #fff;
  color: #000 !important;
  font-weight: 400 !important;
  border-right: 7px solid #f9da55;
}

/* .second-header-sec  .dpq{
    background-color: #A6A6A6;
  } */

.second-header-sec .login {
  font-size: 18px;
  color: #000;
  padding-left: 16px;
  margin-top: 6px;
}

.second-header-sec .navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  max-width: 1000px;
  margin-top: -1px;
  width: 100%;
  left: calc(50% - 500px);
}

/* .second-header-sec  button.navbar-toggler.collapsed {
    font-size: 16px;
} */
.second-header-sec span.navbar-toggler-icon {
  font-size: 15px;
}

.second-header-sec .all-icon {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.second-header-sec .input-group-box {
  position: absolute;
  top: 51px;
  width: 425px;
  z-index: 1;
  left: -163%;
}

.second-header-sec .input-group-box .form-control {
  border-radius: 0%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #707070;
  border-radius: 19px;
  font-size: 13px;
}

.deskdroppp {
  display: block;
}
.mobdroppp {
  display: none;
}
/* mobile view accord start */
.mobdroppp .accordion-item {
  border: 0px;
}
.mobdroppp button.accordion-button.collapsed {
  padding: 10px 10px 6px 10px;
}

.mobdroppp .accordion-button:not(.collapsed) {
  color: #383838;
  background-color: #fff;
  box-shadow: none;
  padding: 10px 10px 6px 10px;
  /* background-color: var(--bs-accordion-active-bg); */
  /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
}

.second-header-sec .mobdroppp .drppp {
  height: 200px;
  overflow-y: scroll;
}
.second-header-sec .mobdroppp .accordion-collapse.collapse.show {
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}
/* mobile view accord end */

@media (min-width: 1200px) and (max-width: 1400px) {
  .second-header-sec .navbar-nav .nav-link {
    font-size: 14px;
    margin: 0px 11px 0px 11px;
  }
  .second-header-sec button.accordion-button {
    font-size: 14px;
  }

  .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 14px;
    /* margin: 25px 11px 0px 11px; */
  }

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 14px;
    /* margin: 25px 11px 0px 11px; */
  }

  .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 14px;
    /* margin: 25px 11px 0px 11px; */
  }

  .second-header-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .second-header-sec .navbar-nav .nav-link {
    font-size: 13px;
  }
  .second-header-sec button.accordion-button {
    font-size: 13px;
  }

  .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    /* margin: 25px 7px 0px 7px; */
  }

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    /* margin: 25px 7px 0px 7px; */
  }

  .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    /* margin: 25px 7px 0px 7px; */
  }
  .second-header-sec .navbar-expand-lg .navbar-nav .dropdown-menu {
    max-width: 900px;
    left: calc(50% - 450px);
  }

  .second-header-sec .login {
    margin-top: 14px;
    font-size: 13px;
  }
  .second-header-sec .treatname_text {
    font-size: 13px;
  }

  /* .second-header-sec .navbar-nav .dropdown {
      width: 299px;
    } */
  /* .second-header-sec .dropdown-toggle::after {
      position: absolute;
      right: -8px;
      margin-top: 7px;
    } */

  /* .second-header-sec .dropdown-menu {
      width: 299px;
    } */
}

@media (min-width: 0px) and (max-width: 991px) {
  .deskdroppp {
    display: none;
  }
  .mobdroppp {
    display: block;
  }

  .second-header-sec .treatname_text {
    font-size: 12px;
  }
  .second-header-sec .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: 0;
  }

  .second-header-sec .droprows1 {
    padding: 15px 0px;
  }
  .second-header-sec .droprows2 {
    padding: 18px 20px;
  }
  .second-header-sec .desktop-view {
    display: none;
  }
  .second-header-sec .all-icon {
    margin-bottom: 10px;
  }

  .second-header-sec .login {
    font-size: 13px;
    color: #000;
    padding-top: 0px;
    margin-top: 2px;
  }
  .second-header-sec .headlogo {
    width: 90px;
    height: 60px;
  }
  .second-header-sec .mobile-view {
    display: block;
  }

  /* .second-header-sec .navbar-toggler {
      color: #000;
      background-color: #fff !important;
      margin-top: 8px;
      padding: 2px 10px;
    } */

  .second-header-sec .navbar-nav .dropdown {
    margin-top: 0px;
  }

  .second-header-sec .navbar-nav .nav-link {
    font-size: 13px;
    margin: 0px 16px 0px 10px;
  }
  .second-header-sec button.accordion-button {
    font-size: 13px;
  }
  /* button.accordion-button.collapsed {
    font-size: 15px;
} */

  .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    margin: 0px 16px 0px 10px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    border-bottom: none;
    margin: 0px 16px 0px 10px;
  }

  .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    border-bottom: none;
    margin: 0px 16px 0px 10px;
  }

  /* .second-header-sec .navbar-nav .dropdown {
      width: 298px;
    } */
  /* .second-header-sec .dropdown-toggle::after {
      position: absolute;
      right: inherit;
      margin-top: 6px;
    } */

  /* .second-header-sec .dropdown-menu {
      width: 298px;
    } */

  .second-header-sec .dropdown-item:hover {
    background: #df4223 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }

  .second-header-sec .dropdown-item:active {
    background: #df4223 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }

  .second-header-sec .dropdown-item {
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }

  .top-header .search .form-control {
    font-size: 13px;
  }

  .top-header .search .search-btn {
    font-size: 13px;
    width: 84px;
  }

  .top-header .headlogo {
    width: 96px;
    height: auto;
  }

  .top-header {
    background-color: #fff;
    padding: 10px 0px;
  }

  .top-header .search {
    margin-top: 15px;
  }

  .second-header-sec .input-group-box {
    position: inherit;
    width: 100%;
    left: 0px;
    top: 8px;
  }
}

.second-header-sec .commonlogodiv {
  position: absolute;
  top: 85vh;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #c4c1c1;
  margin-top: 2px;
}
.second-header-sec .commonlogodiv:hover {
  border: 3px solid #47c756;
  background-color: #fff;
  margin-top: 0px;
}
.second-header-sec .commonlogo {
  width: 30px;
  height: 30px;
}
