.blogmain_section .blogmainimgdiv{
width: 100%;
/* height: 275px; */
}

.blogmain_section  .blogmainimgdiv .blogimage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blogmain_section  .readmore{
    font-size: 12px;
    letter-spacing: 0.24px;
color: #202020;
font-family: "Roboto", sans-serif;

}

.blogmain_section  .subtitle{
    font-size: 20px;
    font-weight: 600;
letter-spacing: 0px;
color: #202020;
font-family: 'CalvinoGrandeVarTrial', sans-serif;
}

.blogmain_section  .blogrecent{
    max-height: 800px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.blogmain_section  .popular_tag{
    display: flex;
    flex-wrap: wrap;
}

.blogmain_section  .puptagttitle{
    font-size: 25px;
    font-weight: 500;
color: #222222;
text-transform: capitalize;
font-family: 'CalvinoGrandeVarTrial', sans-serif;
}
.blogmain_section  .tag1:hover{
   padding-left: 10px;
}
.blogmain_section  .tagss.tags2:hover{
    color: #24642E;
    text-decoration: underline;
}


.blogmain_section  .tagss {
    font-size: 20px;
    font-weight: 400;
    color: #222222;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
    transition: all 0.4s ease;
}

.blogmain_section  .blogmaincont2{
    padding-left: 25px;
}
.blogmain_section  .pagination {
   
    justify-content: center;
}

.blogmain_section  .page-link {
    border: none;
}

@media (min-width: 0px) and (max-width: 485px){
   
.blogmain_section  .blogmaincont2{
    padding-left: 0px;
}
.blogmain_section  .puptagttitle {
    font-size: 18px;
}
.blogmain_section  .tagss {
    font-size: 15px;
}
.blogmain_section  .blogrecent {
    max-height: 400px;
}
   
  }
  @media (min-width: 486px) and (max-width: 767px){
    .blogmain_section  .blogmaincont2{
        padding-left: 0px;
    }
    .blogmain_section  .puptagttitle {
        font-size: 19px;
    } 
    .blogmain_section  .tagss {
        font-size: 16px;
    }
    .blogmain_section  .blogrecent {
        max-height: 500px;
    }
   
  }
  
  @media (min-width: 768px) and (max-width: 991px){
    .blogmain_section  .blogmaincont2{
        padding-left: 0px;
    }
    .blogmain_section  .puptagttitle {
        font-size: 21px;
    }
    .blogmain_section  .tagss {
        font-size: 17px;
    }
    .blogmain_section  .blogrecent {
        max-height: 600px;
    }
       
  }
  @media (min-width: 992px) and (max-width: 1199px){
    .blogmain_section  .puptagttitle {
        font-size: 23px;
    }
    .blogmain_section  .tagss {
        font-size: 18px;
    }
    .blogmain_section  .blogrecent {
        max-height: 700px;
    }
   
  }