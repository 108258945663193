.offer_section{
    padding: 100px 0px; 
    text-align: center;
    background-image: url(../../../../public/assets/images/Treatments/treat22.png);
    aspect-ratio: 1/0.2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}
.offer_section .digittext {
    font-family: 'ChopinScript';
    font-size: 50px;
    padding-left: 10px;
}

@media (min-width: 0px) and (max-width: 320px) {
    .offer_section {
        padding: 40px 0px;
    }
    .offer_section .digittext {
        font-size: 30px;
    }

}

@media (min-width: 321px) and (max-width: 485px) {
    .offer_section {
        padding: 50px 0px;
    }
    .offer_section .digittext {
        font-size: 30px;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .offer_section {
        padding: 55px 0px;
    }
    .offer_section .digittext {
        font-size: 35px;
    }
}

@media (min-width: 576px) and (max-width: 765px) {
    .offer_section {
        padding: 65px 0px;
    }
    .offer_section .digittext {
        font-size: 40px;
    }
}