.cont_sectionn {
    position: relative;
    background-image: url('../../../public/assets/images/contact/clean.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 40px 0px;
}

.cont_sectionn  .contact_section {}

.cont_sectionn  .contact_section .fonticndiv {
    width: 32px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 50%;
    color: #A6A6A6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont_sectionn  .contact_section .leftdivv {
    height: 100%;
    width: 100%;
    background-color: #FFF;
    padding: 30px 70px 20px 70px;
}

.cont_sectionn  .contact_section .leftdivv1 {
    height: 100%;
    width: 100%;
    padding: 30px;
    background-color: #A6A6A6;
}

.cont_sectionn  .contact_section .btnnnn {
    background: #535353 0% 0% no-repeat padding-box;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}
.cont_sectionn  .contact_section .btnnnn:hover {
    background: #000 0% 0% no-repeat padding-box;
   
}

.cont_sectionn  .contact_section .btndiv {
    padding-top: 50px;
}

.cont_sectionn  .contact_section .contactus {
    font-size: 70px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #202020;
}

.cont_sectionn  .contact_section .cont_text {
    font-size: 13px;
    font-weight: 500;
    color: #FFFFFF;
    font-family: "Montserrat";
}

.cont_sectionn  .contact_section .contact_section {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0px;
}

.cont_sectionn  .contact_section input#exampleForm\.ControlInput1 {
    border: none;
    border-bottom: 1px solid #616161;
    border-radius: 0px;
    font-size: 15px;
    color: #000;
    font-family: "Roboto", sans-serif;
}

.cont_sectionn  .contact_section textarea#exampleForm\.ControlTextarea1 {
    border: none;
    border-bottom: 1px solid #616161;
    border-radius: 0px;
    font-size: 15px;
    color: #000;
    font-family: "Roboto", sans-serif;
}

.cont_sectionn  .contact_section .react-tel-input .form-control {
    border:none;
    border-bottom: 1px solid #616161;
    border-radius: 0px;
    width: 100%;
    color: #000;
    font-family: "Roboto", sans-serif;
}

.cont_sectionn  .contact_section .react-tel-input .form-control:focus {
   
    background-color: #fff;
  
    box-shadow: none;
}
.cont_sectionn  .contact_section  .react-tel-input .flag-dropdown {
    background-color: #ffff;
    border: none;
    border-bottom: 1px solid #616161;
    border-radius: 0px;
}
.cont_sectionn iframe.gmap_iframe {
    width: 100%;
    height: 100%;
}

  /* .map_section iframe.gmap_iframe {
    width: 100%;
   
    height: 100%;
} */


.cont_sectionn  .hidden {
    /* height: 0px; */
    /* display: none; */
    /* -webkit-animation: scale-down-ver-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-ver-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.cont_sectionn  .visible {
    /* height: 100%; */
    /* display: block; */
    /* -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
}



/* @-webkit-keyframes scale-down-ver-bottom {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(0.3);
              transform: scaleY(0.3);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  @keyframes scale-down-ver-bottom {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(0.3);
              transform: scaleY(0.3);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  } */

/* .map_section {
    position: absolute;
    width: 100%;
    bottom: 0;
    }  */
    .cont_sectionn  .gmap_canvas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 0;
    /* transition: max-height 2s ease-in-out; */
    /* transition: max-height 2s ease-in-out, transform 2s ease-in-out; */
    transition: height 0.4s ease-in-out;
}

.cont_sectionn  .gmap_canvas.show {
    height: 100% !important;
    /* transition: max-height 2s ease-in-out; */
    /* transition: max-height 2s ease-in-out, transform 2s ease-in-out; */
    /* You can adjust this value based on the map height */
}

.cont_sectionn  .angleee {
    position: absolute;
    width: 100%;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    height: 129px;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont_sectionn .angleicnss {
    font-size: 40px;
}

@media (min-width: 0px) and (max-width: 485px) {
    .cont_sectionn  .contact_section .leftdivv {

        padding: 30px 20px 20px 20px;
    }

    .cont_sectionn  .contact_section .btndiv {
        padding-top: 30px;
    }

    .cont_sectionn  .contact_section .contactus {
        font-size: 30px;
    }
    .cont_sectionn .angleicnss {
        font-size: 20px;
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .cont_sectionn  .contact_section .leftdivv {

        padding: 30px 20px 20px 20px;
    }

    .cont_sectionn .contact_section .btndiv {
        padding-top: 30px;
    }

    .cont_sectionn  .contact_section .contactus {
        font-size: 40px;
    }
    .cont_sectionn .angleicnss {
        font-size: 24px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .cont_sectionn .contact_section .leftdivv {

        padding: 30px 20px 20px 20px;
    }

    .cont_sectionn  .contact_section .contactus {
        font-size: 50px;
    }
    .cont_sectionn .angleicnss {
        font-size: 28px;
    }
}




@-webkit-keyframes scale-up-ver-bottom {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}

@keyframes scale-up-ver-bottom {
    0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}