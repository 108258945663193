.whychoose_section {
    padding: 80px 0px;
    background: transparent linear-gradient(360deg, #fcedaa 0%, #f2e7be 16%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
}

@media (min-width:0px) and (max-width:485px) {
    .whychoose_section {
        padding: 40px 0px;
    }
}
@media (min-width:486px) and (max-width:575px) {
    .whychoose_section {
        padding: 50px 0px;
    }
}
@media (min-width:576px) and (max-width:767px) {
    .whychoose_section {
        padding: 60px 0px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .whychoose_section {
        padding: 70px 0px;
    }
}