
 .Privacy_Policy_Banner .Banner_holder {
    background-image: url(../../../../public/assets/images/Privacy_Policy/Privacy_Policy_Banner.png);
    aspect-ratio: 1/0.2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }

.Privacy_Policy  .Banner_holder .text-holder h1 {
  text-align: left;
  font-size: 70px;
  font-weight: 600;
  color: #222222;
  text-transform: capitalize;
}

@media (min-width: 0px) and (max-width: 320px) {
    .Privacy_Policy  .Banner_holder .text-holder h1 {
    font-size: 20px;
  }


}

@media (min-width: 321px) and (max-width: 485px) {
    .Privacy_Policy   .Banner_holder .text-holder h1 {
    font-size: 22px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
    .Privacy_Policy  .Banner_holder .text-holder h1 {
    font-size: 25px;
  }
}

@media (min-width: 576px) and (max-width: 765px) {
  .Privacy_Policy .Banner_holder .text-holder h1 {
        font-size: 30px;
    }
}

@media (min-width: 766px) and (max-width: 991px) {
    .Privacy_Policy  .Banner_holder .text-holder h1 {
    font-size: 35px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
    .Privacy_Policy  .Banner_holder .text-holder h1 {
    font-size: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .Privacy_Policy  .Banner_holder .text-holder h1 {
    font-size: 50px;
  }
}
