.aboutsilm_section {
    padding: 60px 0px;
}

.aboutsilm_section .imgss {
    /* height: 600px; */
    width: 100%;
}

.aboutsilm_section .imgmain {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
}

.aboutsilm_section .bottomtitles {
    display: flex;
    align-items: end;
}

.aboutsilm_section .yeartext {
    font-size: 116px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #A6A6A6;
}
.aboutsilm_section .noyeartext {
    font-family: 'ChopinScript';
    padding-left: 50px;
    line-height: 90px;
}

.aboutsilm_section .teeext1 {
    text-align: start;
    padding-top: -7px;
    margin-top: -40px;
    margin-left: 173px;
    font-family: 'CalvinoGrandeVarTrial', sans-serif;
}

@media (min-width: 0px) and (max-width: 485px) {
    .aboutsilm_section {
        padding: 20px 0px;
    }
    .aboutsilm_section .yeartext {
padding-left: 10px;
        font-size: 40px;
        line-height: 36px;

    }
    .aboutsilm_section .sevicewith{
        line-height: 25px !important;
    }
    .aboutsilm_section .imgss {
        /* height: auto; */
        width: 100%;
    }
    /* .aboutsilm_section .bottomtitles {
        justify-content: space-between;
    } */
    .aboutsilm_section .teeext1 {
        margin-top: -10px;
        margin-left: 110px;
        /* text-align: end; */
    }
    .aboutsilm_section  .aboutmain{
        padding-top: 30px;
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .aboutsilm_section {
        padding: 30px 0px;
    }
    .aboutsilm_section .yeartext {

        font-size: 50px;
        padding-left: 12px;


    }
    .aboutsilm_section .noyeartext {
        line-height: 50px;
    }
    .aboutsilm_section .sevicewith{
        line-height: 30px !important;
    }
    .aboutsilm_section .imgss {
        /* height: auto; */
        width: 100%;
    }
    /* .aboutsilm_section .teeext1 {
        margin-top: -25px;
        margin-left: 80px;
    } */
    /* .aboutsilm_section .bottomtitles {
        justify-content: space-between;
    } */
    .aboutsilm_section .teeext1 {
        margin-top: -20px; 
        margin-left: 128px;
        /* text-align: end; */
    }
    .aboutsilm_section  .aboutmain{
        padding-top: 40px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .aboutsilm_section {
        padding: 40px 0px;
    }
    .aboutsilm_section .yeartext {

        font-size: 86px;
        padding-left: 20px;

    }
   
    .aboutsilm_section .imgss {
        /* height: auto; */
        width: 100%;
    }
    .aboutsilm_section .teeext1 {
        margin-top: -30px;
        margin-left: 80px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .aboutsilm_section .yeartext {

        font-size: 96px;
        padding-left: 30px;

    }
    .aboutsilm_section .imgss {
        /* height: auto; */
        width: 100%;
    }
    .aboutsilm_section .teeext1 {
        margin-top: -25px;
        margin-left: 135px;
    }
}