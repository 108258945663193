.servicecard_main{
    background: #F6F6F6 0% 0% no-repeat padding-box;
    padding: 20px;
}
.servicecard_main .swiper-pagination.swiper-pagination-bullets-dynamic.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}
.servicecard_main .servicecardimage_div {
  aspect-ratio: 3/2;
  width: 100%;
  /* height: 214px; */
  overflow: hidden;
}

.servicecard_main  .content_divss {
  height: 82px;
}
.servicecard_main .sertitlediv {
  height: 61px;
}

.servicecard_main .servicecardimage{
    width: 100%;
    height: 100%;
    transition: transform .5s ease-in-out;
}
.serviceeeee:hover .servicecardimage {
    transform: scale(1.1) rotate(3deg);
  }

  .servicecard_main .small_text{
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  }
  .servicecard_main .serviceicn {
    color: #202020;
    opacity: 1;
    font-size: 20px;
    transition: padding-left 0.4s ease-in-out, transform 0.7s ease-in-out;
    font-family: "Roboto";
}
  .serviceeeee:hover .serviceicn{
   padding-left: 10px;
   transform: translateX(10px);
  }

  .servicecard_main .sertitlediv .carditle {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #202020;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    font-family: 'CalvinoGrandeVarTrial', sans-serif;
}

  @media (min-width: 0px) and (max-width: 485px){
    .servicecard_main .servicecardimage_div{
        height: 170px;
    }
    .servicecard_main .sertitlediv{
      height: 45px;
    }
    .servicecard_main  .content_divss {
      height: 65px;
  }
  .servicecard_main .sertitlediv .carditle{
    font-size: 15px;  
  }
    
  }
  @media (min-width: 486px) and (max-width: 767px){
    .servicecard_main .servicecardimage_div{
        height: 170px;
    }
    .servicecard_main  .content_divss {
      height: 68px;
  }
  .servicecard_main .sertitlediv .carditle{
    font-size: 16px;  
  }
  .servicecard_main .sertitlediv {
    height: 50px;
}
  }
  
  @media (min-width: 768px) and (max-width: 991px){
    .servicecard_main  .content_divss {
      height: 70px;
  }
  .servicecard_main .sertitlediv .carditle{
    font-size: 17px;  
  }
  .servicecard_main .sertitlediv {
    height: 51px;
}
  }
  @media (min-width: 992px) and (max-width: 1199px){
    .servicecard_main  .content_divss {
      height: 75px;
  }
  .servicecard_main .sertitlediv .carditle{
    font-size: 18px;  
  }
  .servicecard_main .sertitlediv {
    height: 55px;
}
    
  }