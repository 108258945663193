.dentfall_section{
    padding: 70px 0px;
}

.dentfall_section .fallingimgdiv{
    width: 100%;
    
}
.dentfall_section .fallingimage{
    width: 100%;
    height: 100%;
}

.dentfall_section .fillingmain_div {
    height: 100%;

    display: inline-grid;
    align-content: space-between;
}

.dentfall_section .dentaltext{
    font-size: 50px;
    font-weight: bold;
color: #202020;
font-family: 'CalvinoGrandeVarTrial', sans-serif;
}




@media (min-width: 0px) and (max-width: 485px) {
    .dentfall_section {
        padding: 30px 0px;
    }
    .dentfall_section .dentaltext {
        font-size: 30px;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .dentfall_section {
        padding: 40px 0px;
    }
    .dentfall_section .dentaltext {
        font-size: 35px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .dentfall_section {
        padding: 50px 0px;
    }
    .dentfall_section .dentaltext {
        font-size: 40px;
    }
}