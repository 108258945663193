.gallaryswiper_section .gallary_swiper_main .imagebig_div {
  height: 385px;
  width: 100%;
  margin-bottom: 17px;
}
.gallaryswiper_section .gallary_swiper_main .imgbig {
  height: 100%;
  width: 100%;
}
.gallaryswiper_section .gallary_swiper_main .imgsmall {
  width: 100%;
  height: 100%;
}
.gallaryswiper_section .gallary_swiper_main .imagesmall_div {
  height: 184px;
  width: 100%;
  margin-bottom: 17px;
}

.gallaryswiper_section
  .gallary_swiper_main
  .span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  /* background: a6a6a6;
    color: a6a6a6; */
}

.gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet-active {
  width: 44px !important;
  height: 5px;
  border-radius: 5px;
  /* opacity: var(--swiper-pagination-bullet-opacity, 1); */
  background: #a5a5a5;
}

.gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet {
  background: #a5a5a5;
  width: 22px;
  border-radius: 5px;
  height: 5px;
}

.gallaryswiper_section .gallary_swiper_main .swiper {
  padding-bottom: 50px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.gallaryswiper_section .mobileviewss {
  display: none;
}

@media (min-width: 0px) and (max-width: 485px) {
  .gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet-active {
    width: 28px !important;
  }

  .gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet {
    width: 14px;
  }
  .gallaryswiper_section .mobileviewss {
    display: block;
  }
  .gallaryswiper_section .desktopvieww {
    display: none;
  }
  .gallaryswiper_section .gallary_swiper_main .swiper {
    padding-bottom: 40px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}
@media (min-width: 486px) and (max-width: 767px) {
  .gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet-active {
    width: 32px !important;
  }

  .gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet {
    width: 16px;
  }
  .gallaryswiper_section .mobileviewss {
    display: block;
  }
  .gallaryswiper_section .desktopvieww {
    display: none;
  }
  .gallaryswiper_section .gallary_swiper_main .swiper {
    padding-bottom: 40px;
    margin-bottom: 35px;
    margin-top: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet-active {
    width: 36px !important;
  }

  .gallaryswiper_section .gallary_swiper_main .swiper-pagination-bullet {
    width: 18px;
  }

  .gallaryswiper_section .gallary_swiper_main .imagesmall_div {
    height: 140px;
}
.gallaryswiper_section .gallary_swiper_main .imagebig_div {
    height: 297px;
}

  .gallaryswiper_section .mobileviewss {
    display: none;
  }
  .gallaryswiper_section .desktopvieww {
    display: block;
  }
  .gallaryswiper_section .gallary_swiper_main .swiper {
    padding-bottom: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .gallaryswiper_section .gallary_swiper_main .imagesmall_div {
        height: 150px;
    }
    .gallaryswiper_section .gallary_swiper_main .imagebig_div {
        height: 317px;
    }
}







