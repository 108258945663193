.expert_section{
  padding: 60px 0px 20px 0px;
}

.expert_section .ourimgdiv{
  width: 100%;
  margin-top: -70px;
  transition: margin-top 0.3s ease-out;
  /* -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both; */
 
}

.dnonnness{
  -webkit-animation: flip-vertical-fwd 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}
.expert_section .cardmain_div:hover .ourimgdiv{
width: 100%;
margin-top: 0px;

}
.dnonnne{
display: none;
-webkit-animation: flip-vertical-right 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
        animation: flip-vertical-right 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;

}

.expert_section .ourimg{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.expert_section .text_divs{

opacity: 0;
overflow: hidden;
padding: 10px;
height: 80px;
transition: all 0.3s ease;
}

.expert_section .cardmain_div:hover .text_divs{

opacity: 1;

transition: all 0.3s ease;

}

.expert_section .textname{
  font-size: 20px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #202020;
  display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
-webkit-line-clamp: 1;
}

.expert_section  .loremtext {
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #202020;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;
  transform: rotateY(-180deg);
}

.textmains{
  -webkit-animation: flip-vertical-fwd 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

.expert_section .textplace{

  font-size: 15px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #202020;
  display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
-webkit-line-clamp: 1;
}
.expert_section .cardmain_div{
 
margin-bottom: 80px ;  
transition: margin-bottom 0.3s ease-out;
}
.expert_section .cardmain_div:hover {
  background: transparent linear-gradient(180deg, #ffe05b 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 0px;
}
  .expert_section  .backgrdd {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      background: transparent linear-gradient(180deg, #F9DA55 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      height: 70%;
      -webkit-animation: flip-vertical-fwd 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
        animation: flip-vertical-fwd 0.3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
      z-index: -1;
  }

  .expert_section .cardetailsdiv{
      position: relative;
      width: 100%;
      padding: 30px 15px;
  }


  .expert_section .expertmain .swiper-pagination-bullet-active {
    width: 28px !important;
    height: 5px;
    border-radius: 5px;
    /* opacity: var(--swiper-pagination-bullet-opacity, 1); */
    background: #a5a5a5;
    opacity: 0;
   
  }
  
  .expert_section .expertmain .swiper-pagination-bullet {
    background: #a5a5a5;
    width: 14px;
    border-radius: 5px;
    height: 5px;
    opacity: 0;
  }
 

@-webkit-keyframes flip-vertical-fwd {
0% {
  -webkit-transform: translateZ(0) rotateY(0);
          transform: translateZ(0) rotateY(0);
}
100% {
  -webkit-transform: translateZ(160px) rotateY(180deg);
          transform: translateZ(160px) rotateY(180deg);
}
}
@keyframes flip-vertical-fwd {
0% {
  -webkit-transform: translateZ(0) rotateY(0);
          transform: translateZ(0) rotateY(0);
}
100% {
  -webkit-transform: translateZ(160px) rotateY(180deg);
          transform: translateZ(160px) rotateY(180deg);
}
}



.ourimg{
  position: relative;
}

.aaa{
  position: absolute;
  left: 0;
  top:0
}

@media (min-width:0px)and (max-width:767px){
  .expert_section .expertmain .swiper-pagination-bullet-active {
    
    opacity: 1;
  }
  
  .expert_section .expertmain .swiper-pagination-bullet {
   opacity: 1;
  }
}