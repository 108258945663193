
.counter_section{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 40px 0px;
    margin-top: 5%;
}

.counter_section .counts{
    font-size: 65px;
    font-weight: bold;

color: #202020;
font-family: "Roboto", sans-serif;
}

.counter_section .offertext{
    font-size: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;

color: #202020;
border-left: 3px solid #F9DA55;
padding-left: 4px;
}

@media (min-width: 0px) and (max-width: 485px){
    .counter_section{
        padding: 20px 0px;
        text-align: center;
       
    }
    .counttext {
        text-align: -webkit-center;
    }
    .counter_section .counts {
        font-size: 30px;
    }
    
    .counter_section .offertext {
        font-size: 14px;
        max-width: fit-content;
        border-left: 0px solid #F9DA55;
        padding-left: 0px;
    }
    .counter_section .conter{
text-align: center;
    }
  }
  @media (min-width: 486px) and (max-width: 767px){
        .counter_section{
            padding: 25px 0px;
            text-align: center;
        }
        .counter_section .conter{
            text-align: center;
                }
        
        .counter_section .counts{
            font-size: 37px;
        }
        
        .counter_section .offertext {
            font-size: 14px;
            max-width: fit-content;
            border-left: 0px solid #F9DA55;
            padding-left: 0px;
        }
        .counttext {
            text-align: -webkit-center;
        }
  }
  
  @media (min-width: 768px) and (max-width: 991px){
    .counter_section{
        padding: 30px 0px;
    }
    .counter_section .conter{
        text-align: center;
            }
    
    .counter_section .counts{
        font-size: 47px;
    }
    
    .counter_section .offertext{
        font-size: 17px;
        border-left: 0px solid #F9DA55;
        padding-left: 0px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px){
    .counter_section{
        padding: 35px 0px;
    }
    .counter_section .conter{
        text-align: center;
            }
    
    .counter_section .counts{
        font-size: 55px;
    }
    
    .counter_section .offertext{
        font-size: 19px;
        border-left: 0px solid #F9DA55;
        padding-left: 0px;
    }
    
  }