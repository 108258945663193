
.fcircleee{
    color: #fff;
    background-color: #A6A6A6;
    border-radius: 50%;
    border: 2px solid #A6A6A6;
    transform: rotate(-45deg);
  }

  .aaaa button:hover .fcircleee {
    background-color: #cfc9c9;
}
  .aaaa button {
    position: relative;
    overflow: hidden;
    display: flex;
    background: #fff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    color: #A6A6A6;
    font-weight: 500;
    cursor: pointer;
    font-size: 24px;
    border-radius: 50%;
    z-index: 1;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
  }
  .aaaa button:active{
    transform: scale(0.35);
  }
  .aaaa button::before {
    content: '';
    position: absolute;
    inset: -1px 4px;
    background: #F9DA55;
    transition: 500ms;
    animation: rotate 0.6s linear infinite;
    z-index: -1;
}
  .aaaa button:hover::before{
    inset: -1px;
  }
  .aaaa button::after {
    content: '';
    position: absolute;
    inset: 2px;
    border-radius: 50%;
    background: #fff;
    z-index: -1;
  }
  /* @keyframes rotate {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate( 0deg);
    }
  }  */


  /* 

    .Specialist .video {
      position: relative;
    }
    
    .Specialist .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    
    .Specialist .hide-background .specialist-main {
      display: none;
    }
    
    .Specialist .hide-background .content {
      display: none;
    }
    
    .Specialist .video-class-grid {
      width: 100%;
      height: auto;
    } */