.bookapoint_section {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 50px 0px;
}

.bookapoint_div_main{
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.titlespan{
    background: #F9DA55 0% 0% no-repeat padding-box;
}

.apointsec_div .text {
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
}

@media (min-width: 0px) and (max-width: 485px){
    .bookapoint_div_main{
        flex-direction: column;
         align-items: start;
     }
     .apointsec_div .text {
        font-size: 13px;
    }
    .apointsec_div {
        padding-top: 15px;
    }
   
  }
  @media (min-width: 486px) and (max-width: 767px){
    .bookapoint_div_main{
        flex-direction: column;
         align-items: start;
     }
     .apointsec_div {
        margin-top: 4%;
    }
    .apointsec_div .text {
        font-size: 14px;
    }
    .apointsec_div {
        padding-top: 15px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px){
  
    /* .bookapoint_div_main{
       flex-direction: column;
        align-items: start;
    } */
    .apointsec_div .text {
        font-size: 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px){
  
   
  }