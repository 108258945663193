.Privacy_Policy_Content {
  margin-top: -5%;
  margin-bottom: 10%;
}

.Privacy_Policy_Content .Privacy_Policy_Content_holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 25px 25px;
}

.Privacy_Policy_Content
  .Privacy_Policy_Content_holder
  .list-holder
  .text-holder {
  margin: 40px 0px;
}

.Privacy_Policy_Content
  .Privacy_Policy_Content_holder
  .list-holder
  .text-holder
  h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #2e2e2e;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

.Privacy_Policy_Content
  .Privacy_Policy_Content_holder
  .list-holder
  .text-holder
  p {
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  color: #2e2e2e;
  font-family: "Roboto", sans-serif;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder {
    margin: 18px 0px;
  }

  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder
    h3 {
    font-size: 15px;
  }

  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder
    p {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder {
    margin: 18px 0px;
  }

  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder
    h3 {
    font-size: 15px;
  }

  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder
    p {
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder {
    margin: 18px 0px;
  }
}

@media (min-width: 576px) and (max-width: 765px) {
  .Privacy_Policy_Content
    .Privacy_Policy_Content_holder
    .list-holder
    .text-holder {
    margin: 18px 0px;
  }
}
