Specialist css started

.Specialist .specialist-main img.specialist {
    width: 100%;
    height: 100%;
}

.Specialist  .clicked{
    display: none;
}
.Specialist  .notclicked{
    display: block;
}
.Specialist h1.title {
    margin-top: 9%;
}

.Specialist img.specialist-image {
    width: 100%;
    height: 100%;
}

.Specialist .play-button {
    width: 152px;
    height: 152px;
    position: relative;
    cursor: pointer;
}

.Specialist .play-close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: calc(100% + 15px);
    border: none;
    outline: none;
    background: none;
    opacity: 0;
    cursor: pointer;
}

.Specialist .play-close::before,
.play-close::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(45deg);
    background-color: #fff;
}
.Specialist .play {
    left: 47%;
    top: 54%;
    position: absolute;
}
.Specialist .circle-main {
    padding: 11px 11px;

    margin-left: -25px;
    border-radius: 100px;
    border: 1px solid grey;

    margin-top: 6px;
}

.Specialist .Specialist .play-close::after {
    transform: rotate(-45deg);
}

.Specialist .play-circles {
    background-color: white;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.Specialist .play-triangle {
    width: 600px;
    height: 400px;
    background-color: #fff;
    cursor: pointer;
}
.Specialist .specialist-main .play-button {
    transform: translate(-50%, 50%);
    left: 0px;
    top: 0px;
    right: 0px;
    width: 63px;
    height: 69px;
    position: relative;
    cursor: pointer;
}

.Specialist .specialist-main .main-play-btn {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.Specialist .title-main {
    margin-top: 9%;
} 

.Specialist .traingle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Specialist .circle:hover .traingle {
    color: #ccc;
}

/* Specialist css End */

/* New css  */

.Specialist {
    position: relative;
}
.Specialist .video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    padding: 20px;
    z-index: 1000;
}
.Specialist .content {
    padding: 20px 0px;
    margin-left: 8%;
}
.Specialist .video-modal video {
    width: 100%;
    max-width: 900px;
    height: auto;
}
.Specialist .video-class-grid {
    width: 100%;
    
}
.Specialist .video {
    /* position: absolute; */
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    
   
}

.Specialist .videoss{
    padding: 50px 0px;
    text-align: center;
}
.Specialist .video .text-sub {
    text-align: center;
}
.Specialist video.video-class-grid {
    width: 100%;
    height: 100%;
}

.specialist-main {
    position: relative;
}

.Specialist .video .text-mini {
    text-align: center;
}
.Specialist .close {
    font-size: 23px;
    position: absolute;
    top: 81px;
    right: 31px;
}
.hide-background .specialist-main,
.hide-background .content {
    visibility: hidden;
}
.hidden-content {
    display: none;
}

.circle {
    height: 40px;
    border: 1px solid grey;
    width: 40px;
    border-radius: 100px;
    position: absolute;
    top: 15px;
    left: 11px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reversee{
    flex-direction: row-reverse;
}
/* media Query Started */

@media (min-width: 320px) and (max-width: 485px) {
   
    .Specialist h1.title {
        margin-top: -1%;
    }
    .Specialist .video {
       
    }
    .Specialist .videoss{
        padding: 20px 0px;
    }
    .Specialist .close {
        font-size: 19px;
        top: 25px;
        right: 20px;
    }
    .hide-background .specialist-main,
    .hide-background .content {
        height: 398px;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
   
    .Specialist .video {
       
    }
    .Specialist .videoss{
        padding: 25px 0px;
    }
    .Specialist .close {
        font-size: 19px;
        top: 35px;
        right: 20px;
    }

    .Specialist img.specialist-image {
        width: 100%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
   
    .Specialist .video {
        
    }
    .Specialist .videoss{
        padding: 25px 0px;
    }
    .Specialist .close {
        font-size: 19px;
        top: 35px;
        right: 20px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
   
    .Specialist .video {
        
    }
    .Specialist .videoss{
        padding: 35px 0px;
    }
    .Specialist .close {
        top: 43px;
        right: 20px;
    }
}


