.clientsay_section .clientsayimgdiv {
  padding-left: 135px;
}

.clientsay_section .clientsayimage {
  width: 164px;
  height: 139px;
}
.clientsay_section .clientsay_div_main .clientsayswiper .text-testimonial {
}

.clientsay_section .clientsay_div_main .clientsayswiper .border-main {
  width: 138px;
  margin-top: 27px;
  border-bottom: 2px solid #a6a6a652;
}

.clientsay_section .clientsay_div_main .clientsayswiper {
  margin-top: 120px;
}

.clientsay_section .clientsay_div_main .clientsayswiper .p-text {
  margin-top: 5%;
}
.clientsay_section span.swiper-pagination-bullet {
  display: none;
}
.clientsay_section .icon-main {
  font-size: 19px;
}
.clientsay_section .slider-btns {
  position: relative;
}
.clientsay_section .slider-btns .back-btn {
  padding: 9px 9px 9px 0px;
  bottom: -29px;
  position: absolute;
  height: 40px;
  width: 29px;
  left: -1px;
  z-index: 1;
}
.clientsay_section .slider-btns .next-btn {
  left: 28px;
  padding: 9px 0px 9px 9px;
  bottom: -29px;
  position: absolute;

  height: 40px;
  width: 29px;
  z-index: 1;
}
.clientsay_section .clientsay_div {
  margin-bottom: 32%;
  margin-top: 6%;
}

@media (min-width: 0px) and (max-width: 485px) {
  .clientsay_section .clientsayimage {
    width: 76px;
    height: 75px;
  }
  .clientsay_section .clientsay_div {
    margin-bottom: 0%;
    margin-top: 6%;
  }
  .clientsay_section .clientsay_div_main .clientsayswiper {
    margin-bottom: 20%;
    margin-top: 20px;
  }
  .clientsay_section .clientsay_div_main .clientsayswiper .border-main {
    margin-top: 15px;
}
  .clientsay_section .clientsayimgdiv {
    padding-left: 100px;
  }
  .clientsay_section .icon-main {
    font-size: 15px;
  }
  /* .clientsay_section .clientsay_div_main .clientsayswiper {
    margin-top: 20px;
  } */
}
@media (min-width: 486px) and (max-width: 575px) {
  .clientsay_section .clientsayimage {
    width: 76px;
    height: 75px;
  }
  .clientsay_section .clientsay_div {
    margin-bottom: 0%;
    margin-top: 6%;
  }
  .clientsay_section .clientsay_div_main .clientsayswiper {
    margin-bottom: 20%;
    margin-top: 20px;
  }
  .clientsay_section .icon-main {
    font-size: 16px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .clientsay_section .clientsayimage {
    width: 76px;
    height: 75px;
  }
  .clientsay_section .clientsay_div {
    margin-bottom: 0%;
    margin-top: 6%;
  }
  .clientsay_section .clientsay_div_main .clientsayswiper {
    margin-bottom: 20%;
    margin-top: 20px;
  }
  .clientsay_section .icon-main {
    font-size: 17px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .clientsay_section .clientsayimage {
    width: 76px;
    height: 75px;
  }
  .clientsay_section .clientsay_div {
    margin-bottom: 0%;
    margin-top: 6%;
  }
  .clientsay_section .clientsay_div_main .clientsayswiper {
    margin-bottom: 20%;
    margin-top: 20px;
  }
  .clientsay_section .icon-main {
    font-size: 18px;
  }
}
